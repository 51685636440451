import { CheckBadgeIcon, StarIcon, RocketLaunchIcon } from '@heroicons/react/24/outline'
import ActiveSlider from './ActiveSlider'

export default function ThreeBlocks(props) {
    const { isEnglish } = props

    const features = [
        {
            title: isEnglish ? 'Guaranteed Work' : 'Plannings remplis',
            text:
                isEnglish ?
                    "With increasing needs and not enough doctors, France needs more practitioners to keep up. You’re guaranteed a full agenda from day one should you decide to join us."
                    :
                    "Avec des besoins très importants et une offre de santé encore trop faible, la région Centre a besoin de plus de médecins. Vous avez la garantie d'un agenda rempli et qualitatif dès le premier jour d'activité."
        },
        {
            title: isEnglish ? 'Quality Healthcare' : "Priorité qualité",
            text:
                isEnglish ?
                    'Our doctors work in modern clinics and handle a wide range of treatments, guiding patients towards the best care. This is the ideal place to grow professionally and learn new skills.'
                    :
                    "Nos médecins travaillent dans des cliniques bien équipées et réalisent une large gamme de soins, guidant les patients vers le traitement le plus adapté. C'est l'endroit idéal pour vous développer et acquérir de nouvelles compétences."
        },
        {
            title: isEnglish ? 'Rewarding Careers' : "Carrières enrichissantes",
            text:
                isEnglish ?
                    "You worked hard to become a doctor? Make the choice to upgrade your career in France. Our doctors earn great money on normal working hours. You could too."
                    :
                    "Vous avez travaillé dur pour devenir médecin ? Faites le choix d'accélerer votre carrière avec Neomed. Nos praticiens ont d'excellents revenus en horaires normaux. Pourquoi pas vous?"
        },
    ]
    return (
        <div className="bg-white">
            <div className="relative isolate overflow-hidden pt-12 md:pt-0 shadow-2xl rounded-3xl md:px-16 lg:flex lg:gap-x-20" style={{ backgroundColor: '#231A2E' }}>
                <svg
                    viewBox="0 0 1024 1024"
                    aria-hidden="true"
                    className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] md:left-full md:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
                >
                    <circle r={512} cx={512} cy={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
                    <defs>
                        <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                            <stop stopColor="#7775D6" />
                            <stop offset={1} stopColor="#E935C1" />
                        </radialGradient>
                    </defs>
                </svg>
                <div className="mx-auto text-center lg:mx-0 lg:flex-auto lg:text-left pt-8 pb-24 md:pt-24 md:pb-32">
                    <h2 className="text-3xl px-8 md:px-0 leading-snug text-start md:text-4xl font-semibold md:font-bold text-white">
                        {isEnglish ? 'WHY CHOSE NEOMED?' : 'POURQUOI CHOISIR NEOMED?'}
                    </h2>
                    <div className="careerBlock mt-12">
                        {window.innerWidth > 768 ?
                            <dl className="grid max-w-xl grid-cols-1 gap-x-14 md:gap-x-12 gap-y-6 md:gap-y-10 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
                                {features.map((feature) => (
                                    <div key={feature.name} className="px-6 py-8 md:px-8 md:py-10 md:relative flex flex-col justify-start bg-gray-100 rounded-2xl items-start">
                                        <h4 className="text-xl md:text-3xl font-semibold text-gray-900">{feature.title}</h4>
                                        <dd className="mt-2 md:mt-4 text-sm md:text-base md:leading-7 leading-6 text-gray-800">{feature.text}</dd>
                                    </div>
                                ))}
                            </dl>
                            :
                            <ActiveSlider
                                data={features}
                                slides={1}
                                slidesPerView={1.15}
                                type="fluid"
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
