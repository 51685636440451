import React, { useEffect } from 'react'
import { anchorScroll } from '../../helpers/helperFunctions';

// Import pics
import irina from '../../assets/irina.jpg'
import hadrien from '../../assets/hadrien.jpg'
import anda from '../../assets/anda.jpg'

// Import components
import ContactForm from '../../components/ContactForm';
import AboutHero from '../../components/AboutHero';
import TeamFeature from '../../components/TeamFeature';


function About(props) {
    const { isEnglish } = props

    useEffect(() => {
        if (!localStorage.getItem('about')) {
            const fadeInRight = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('fadeInRight');
                    }
                });
            });
            const fadeInUp = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('fadeInUp');
                    }
                });
            });
            fadeInRight.observe(document.querySelector('.aboutText1'));
            fadeInRight.observe(document.querySelector('.aboutText2'));
            fadeInRight.observe(document.querySelector('.aboutText3'));
            fadeInRight.observe(document.querySelector('.aboutText4'));
            fadeInRight.observe(document.querySelector('.aboutText5'));
            fadeInUp.observe(document.querySelector('.aboutImg1'));
            fadeInUp.observe(document.querySelector('.aboutImg2'));
            fadeInUp.observe(document.querySelector('.aboutImg3'));
            fadeInUp.observe(document.querySelector('.aboutImg4'));
            localStorage.setItem('about', true)
        }
    }, [])


    return (
        <div className='overflow-x-hidden'>
            <div className='mt-28 sm:mt-32'>
                <AboutHero isEnglish={isEnglish} anchorScroll={anchorScroll} />
            </div>

            <div className='relative px-4 sm:px-12 pb-32 sm:pb-44 mt-14 sm:mt-20'>
                <TeamFeature
                    isEnglish={isEnglish}
                    people={[
                        {
                            name: 'Hadrien',
                            role: isEnglish ? 'CEO & Founder' : 'Président & Fondateur',
                            imageUrl: hadrien,
                            bio: isEnglish ?
                                "Hadrien has a rich experience in entrepreneurship. Before founding Neomed, he worked in Switzerland, Portugal and the USA. Multilingual, he understands the difficulties that foreign doctors can experience adapting to the local ways and knows how to support them in order to build a successful career in France."
                                :
                                "Hadrien s'est donné pour mission d'apporter plus de sérénité à l'exercice de la médecine en France. Bien être au travail, exercice collaboratif, soutien à toutes les étapes de l'installation et de la pratique, il souhaite créer de meilleures opportunités de carrière pour des médecins désireux de vivre mieux, travailler en équipe et se développer professionnellement.",
                        },
                        {
                            name: 'Irina',
                            role: isEnglish ? 'Specialist Orthodontist' : 'Orthodontiste Spécialiste',
                            imageUrl: irina,
                            bio: isEnglish ?
                                "Irina is a passionate orthodontist who loves nothing more than to see her patients smile. As a foreign doctor, she has first-hand experience of the big changes that await our candidates and is always available to help them settle in their new life."
                                :
                                "Irina est une orthodontiste spécialiste passionnée par son métier et qui a le goût de redonner le sourire a ses patients. Formée à l'étranger, elle a fait l'expérience du changement qui attend nos candidats étrangers et sait se rendre disponible pour faciliter leur installation en France.",
                        },
                        {
                            name: 'Anda',
                            role: isEnglish ? 'General Dentist' : "Chirurgien-Dentiste",
                            imageUrl: anda,
                            bio: isEnglish ?
                                "Anda has been working in France for over 10 years. She has deep knowledge of the intricacies of the French health system and will assist our new joiners in learning the ways of French medicine."
                                :
                                "Anda est dentiste omnipraticienne en France depuis plus de 10 ans. Elle connaît bien les spécificités du système de santé français et saura assister nos praticiens dans leur début d'exercice dans l'Indre.",
                        },
                    ]}
                />
                <div className='absolute bottom-0 left-0 -z-10 heroPatternClouds w-full sm:pb-10' />
            </div>
            <div className="custom-shape-divider-bottom-1725632977">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
                    <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
                    <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
                </svg>
            </div>

            {/* Contact and form */}
            <div className='px-2 sm:px-8 mt-14 sm:mt-20'>
                <ContactForm isEnglish={isEnglish} />
            </div>

        </div>
    )
}

export default About