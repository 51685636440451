import { GoogleMap, MarkerF } from '@react-google-maps/api'
import { useRef, useCallback, useState, useEffect } from 'react';
import ActiveSlider from './ActiveSlider';

import { mapStyle } from './../helpers/mapStyle'

import saintMaur from '../assets/saintMaur.jpg'
import chateauroux from '../assets/chateauroux.jpg'
import markerActive from '../assets/markerActive.svg'
import markerPassive from '../assets/markerPassive.svg'
import medit from '../assets/medit resized.jpg'

export default function MapLocales(props) {
    const { isEnglish } = props
    const mapRef = useRef()
    const [scrolledLocale, setScrolledLocale] = useState(0)
    const [currentZoom] = useState(12)
    const [showMap, setShowMap] = useState(false)

    const locales = [
        {
            key: 0,
            title: "CHATEAUROUX",
            specialty: isEnglish ? 'General Dentistry' : 'Dentisterie',
            address: '28 avenue Marcel Lemoine, 36000 Châteauroux',
            gmapsUrl: 'https://maps.app.goo.gl/tYFxG93kAfULPwa1A',
            rooms: '2',
            backgroundImage: chateauroux,
            gps: { lat: 46.81599415776411, lng: 1.6970356966100424 }
        },
        {
            key: 1,
            title: "DEOLS",
            specialty: isEnglish ? 'General Dentistry & Family Medicine' : 'Dentisterie & Médecine Générale',
            address: '27 rue Ferdinand Gigot, 36130 Déols',
            gmapsUrl: 'https://maps.app.goo.gl/Y7T5ifV69z7UwGXv5',
            rooms: "4",
            backgroundImage: medit,
            gps: { lat: 46.831237819177936, lng: 1.7006466074184041 }
        },
        {
            key: 2,
            title: "SAINT-MAUR",
            specialty: isEnglish ? 'Orthodontics' : 'Orthodontie',
            address: '1 rue du 8 mai 1945, 36250 Saint-Maur',
            gmapsUrl: 'https://maps.app.goo.gl/rfVEydtK9kSRJrvm9',
            rooms: "3",
            backgroundImage: saintMaur,
            gps: { lat: 46.805649040201686, lng: 1.6394944530561921 }
        },
    ]

    const options = {
        styles: mapStyle,
        disableDefaultUI: true,
        zoomControl: true,
        gestureHandling: window.innerWidth < 500 ? "greedy" : "auto"
    }

    const mapProps = {
        center: {
            lat: 46.815969335540885,
            lng: window.innerWidth > 1024 ? 1.696558025445545 : 1.669558025445545
        },
        zoom: 12
    };

    const mapContainerStyle = (window.innerWidth > 1024 ?
        {
            height: '240px',
            width: '100%',
        }
        :
        {
            height: '25vh',
            width: `calc(${window.innerWidth}px - 5rem)`,
        }
    )

    const onMapLoad = useCallback(map => {
        mapRef.current = map
    }, [])

    useEffect(() => {
        setTimeout(() => {
            setShowMap(true)
        }, 500);
    }, [])

    return (
        <div className="relative px-4 sm:px-20 pb-14 sm:pb-32" style={{ backgroundColor: '#231a2e' }}>
            <div className='absolute -top-1/3 sm:-top-1/2 left-0 w-full -z-10 opacity-100' style={{ backgroundColor: '#231a2e', width: '100vw', height: '550px' }} />
            <div className="bg-white rounded-xl mx-auto mx-10 px-4 sm:px-20 py-12 sm:py-20">
                {window.innerWidth > 1024 && <div className='absolute top-1/2 -right-1/3 rounded-full -z-10 opacity-100' style={{ backgroundColor: '#231A2E', width: '300px', height: '300px' }} />}
                <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
                    <div className="px-2 sm:px-6 md:px-0 lg:pr-4 ">
                        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
                            <h3 className="text-md md:text-xl font-semibold leading-7 text-indigo-600">{isEnglish ? "OUR CLINICS" : "NOS CABINETS"}</h3>
                            <h2 className="mb-2 md:mb-4 md:mt-1 text-3xl font-bold md:text-4xl tracking-tight text-gray-900">
                                {isEnglish ? "Live and work at the heart of France" : "Vivez et travaillez en coeur de France"}
                            </h2>
                            <p className="mt-2 max-w-3xl text-md sm:text-lg leading-7 text-gray-800">{isEnglish ? "We currently operate 3 private practices in one of the most attractive regions for doctors." : "Nous opérons actuellement 3 cabinets, dans l'une des régions les plus attractives pour les médecins."}</p>
                            <dl className="mt-6 sm:my-6 max-w-xl text-gray-600 lg:max-w-none">
                                <div className='googleMaps'>
                                    {showMap && <GoogleMap
                                        id="map"
                                        zoom={mapProps.zoom}
                                        center={mapProps.center}
                                        styles={mapStyle}
                                        mapContainerStyle={mapContainerStyle}
                                        options={options}
                                        onLoad={onMapLoad}
                                    >
                                        {locales.map(locale => (
                                            <MarkerF
                                                key={`${locale.gps.lat}-${locale.gps.lng}`}
                                                className="position-relative shadow-xl"
                                                position={{
                                                    lat: locale.gps.lat,
                                                    lng: locale.gps.lng,
                                                }}
                                                onClick={() => {
                                                    setScrolledLocale(locale.key)
                                                }}
                                                icon={{
                                                    url: scrolledLocale === locale.key ?
                                                        markerActive
                                                        :
                                                        markerPassive,
                                                    origin: new window.google.maps.Point(0, 0),
                                                    anchor: new window.google.maps.Point(25, 25),
                                                    scaledSize: scrolledLocale === locale.key ?
                                                        new window.google.maps.Size(50 * currentZoom / 12, 50 * currentZoom / 12)
                                                        :
                                                        new window.google.maps.Size(40 * currentZoom / 12, 40 * currentZoom / 12)
                                                    ,
                                                }}
                                            />
                                        ))}
                                    </GoogleMap>
                                    }
                                </div>
                            </dl>
                            <div className="flex mt-6 sm:mt-0 items-center justify-center gap-x-6 lg:justify-start">
                                <a
                                    href="https://www.youtube.com/watch?v=emRhBjOQaMY&ab_channel=Paris%2Cjetequitte"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="rounded-md bg-gray-900 text-white px-3.5 py-2.5 text-sm sm:text-md font-semibold shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                                >
                                    {isEnglish ? "Discover Chateauroux" : 'Découvrez Châteauroux'}
                                </a>
                                <div onClick={() => props.anchorScroll('#contact-form')} className="text-sm sm:text-md cursor-pointer font-semibold leading-6 text-gray-900">
                                    {isEnglish ? 'Talk to us ' : 'Parlez nous '}<span aria-hidden="true">→</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sm:px-6 lg:px-0">
                        <div className="relative isolate overflow-hidden bg-indigo-500 sm:px-4 pt-4 sm:pt-6 sm:pb-6 pb-4 sm:mx-auto sm:max-w-2xl shadow-xl rounded-3xl lg:mx-0 lg:max-w-none">
                            {window.innerWidth > 1024 &&
                                <div
                                    aria-hidden="true"
                                    className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-indigo-100 opacity-20 ring-1 ring-inset ring-white"
                                />
                            }
                            <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                                <ActiveSlider
                                    setScrolledLocale={setScrolledLocale}
                                    scrolledLocale={scrolledLocale}
                                    data={locales}
                                    slides={0}
                                    simplified={true}
                                    isEnglish={isEnglish}
                                />
                            </div>
                            <div
                                aria-hidden="true"
                                className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 sm:rounded-3xl"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}